<template>
  <v-card elevation="6" class="my-card">
    <v-card-text class="pb-0 mb-0">
      <v-row>
        <v-col class="d-flex justify-space-between align-center">
          <div style="max-width: 80%">
            <span
              :class="'subtitle-2 ' + (darkTheme ? 'text-white' : 'text-black')"
            >
              {{ info.Opportunity }}
            </span>
          </div>
          <div>
            <v-icon :color="darkTheme ? '' : getIcon.color">
              {{ getIcon.icon }}
            </v-icon>
            <v-icon
              v-if="info.Mustwin"
              :color="darkTheme ? '' : 'orange darken-4'"
            >
              mdi-trophy
            </v-icon>
            <v-menu bottom left :offset-y="true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item @click="$emit('editarCierre')">
                  <v-list-item-icon class="mr-2">
                    <v-icon>mdi-calendar</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Editar fecha de cierre</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <span class="actName">{{ info.Client }}</span>
      <br />
      <span class="actInfo">Fecha de cierre: {{ info.Deadline }}</span>
      <br />
      <template v-if="info.Activity">
        <span class="actInfo">{{ info.Activity }}</span>
        <br />
        <span class="actInfo">{{ getDate(info.ActivityDate) }}</span>
      </template>
    </v-card-text>
    <v-card-actions class="pa-0 ma-0">
      <v-spacer></v-spacer>
      <v-list>
        <v-list-item>
          <v-btn
            link
            color="primary"
            :to="{
              name: 'opportunity',
              params: { id: info.Id.toString() }
            }"
          >
            <v-icon class="mr-2">mdi-eye</v-icon>Detalle
          </v-btn>
        </v-list-item>
      </v-list>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  props: {
    info: { type: Object, required: true }
  },
  methods: {
    sendClose() {
      this.$emit('cardClose')
    },
    getDate(dateString) {
      return moment(dateString).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  computed: {
    ...mapState('account', ['darkTheme']),
    getIcon() {
      let icon = { icon: '', color: '' }

      if (this.info.Status === 'Won') {
        icon.icon = 'mdi-check-bold'
        icon.color = this.darkTheme ? '' : 'success'
      } else {
        let today = new Date()
        let actDate = new Date(this.info.ActivityDate)
        if (actDate >= today) {
          icon.icon = 'mdi-chevron-right-circle'
          icon.color = this.darkTheme ? '' : 'primary'
        } else {
          icon.icon = 'mdi-alert'
          icon.color = this.darkTheme ? '' : 'warning'
        }
      }
      return icon
    }
  }
}
</script>
