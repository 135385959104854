<template>
  <v-container v-if="opportunities">
    <v-row>
      <v-col cols="12">
        <h3>Mis oportunidades</h3>
      </v-col>

      <v-col cols="12" v-if="stages">
        <v-select
          v-model="stage_id"
          :items="stages"
          label="Selecciona un stage"
          item-text="Name"
          item-value="Id"
          @change="getOpportunities(true)"
        ></v-select>
      </v-col>

      <v-col cols="12" class="text-end">
        <v-btn-toggle v-model="filter" mandatory @change="setFilters()">
          <v-btn @click="setFilters()"> Todas </v-btn>
          <v-btn>
            <v-icon :color="darkTheme ? '' : 'primary'"
              >mdi-chevron-right-circle</v-icon
            >
          </v-btn>
          <v-btn>
            <v-icon :color="darkTheme ? '' : 'warning'">mdi-alert</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row class="mb-12">
      <v-col
        cols="12"
        v-for="(item, index) in opportunities"
        :key="index"
        v-scrollfadein
      >
        <OpportunityCard :info="item" @editarCierre="openDialogEdit(item.Id)" />
      </v-col>
    </v-row>
    <v-dialog persistent v-model="dialogEdit" v-if="opportunityToEdit">
      <v-card class="myCard pb-2">
        <v-card-text class="pb-0 mb-0">
          <v-row class="mt-4">
            <v-col cols="12">
              <span
                :class="
                  'subtitle-2 ' + (darkTheme ? 'text-white' : 'text-black')
                "
              >
                {{ opportunityToEdit.Name }}
              </span>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12">
              <v-menu
                v-model="menuDeadline"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                nudge-bottom="40"
                :offset-y="true"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="opportunityToEdit.Deadline"
                    label="Fecha de cierre"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  bottom
                  v-model="opportunityToEdit.Deadline"
                  @input="menuDeadline = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-list-item>
            <v-btn
              color="primary"
              @click="saveOpportunity(true, opportunityToEdit)"
            >
              Guardar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="secondary" @click="dialogEdit = false">
              Cancelar
            </v-btn>
          </v-list-item>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SetOpportunity
      :visible="dialogNewOpportunity"
      :opportunity="opportunity"
      @closeDialog="closeDialog"
      @savedOpportunity="updateOps"
      :key="opkey"
    />

    <v-btn
      color="primary"
      fab
      fixed
      bottom
      right
      @click="dialogNewOpportunity = true"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import OpportunityCard from '@/components/OpportunityCard.vue'
import SetOpportunity from '@/components/SetOpportunity.vue'

export default {
  components: { OpportunityCard, SetOpportunity },
  data() {
    return {
      opportunities: null,
      opportunityToEdit: null,
      dialogEdit: false,
      dialogNewOpportunity: false,
      menuDeadline: false,
      stages: null,
      filter: 0,
      stage_id: 0,
      on_time: true,
      all: true,
      opportunity: {},
      opkey: 0
    }
  },
  methods: {
    test(test) {
      console.log(test)
    },
    setFilters() {
      switch (this.filter) {
        case 0:
          this.stage_id = 0
          this.on_time = true
          this.all = true
          break
        case 1:
          this.on_time = true
          this.all = false
          break
        case 2:
          this.on_time = false
          this.all = false
          break
        default:
          break
      }
      this.getOpportunities(true)
    },
    getOpportunities(showOverlay) {
      let headers = {
        user_id: this.userData.UserId,
        stage_id: this.stage_id,
        on_time: this.on_time,
        all: this.all
      }

      axios
        .get(this.$endpointsBaseUrl + 'Opportunities/GetMyOpportunities', {
          showOverlay: showOverlay,
          headers: headers
        })
        .then((response) => {
          this.opportunities = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    getStages(showOverlay) {
      let headers = {
        id: 0
      }

      axios
        .get(this.$endpointsBaseUrl + 'Stages/Get_Stages', {
          showOverlay: showOverlay,
          headers: headers
        })
        .then((response) => {
          this.stages = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    getOpportunityToEdit(showOverlay, id) {
      let headers = {
        opportunities_id: parseInt(id)
      }

      axios
        .get(this.$endpointsBaseUrl + 'Opportunities/Get_Opportunities', {
          showOverlay: showOverlay,
          headers: headers
        })
        .then((response) => {
          this.opportunityToEdit = response.data[0]
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    saveOpportunity(showOverlay, opportunity) {
      axios
        .post(
          this.$endpointsBaseUrl + 'Opportunities/SetDeadline',
          opportunity,
          {
            showOverlay: showOverlay
          }
        )
        .then(() => {
          this.dialogEdit = false
          this.getOpportunities(true)
        })
        .catch(() => {
          this.dialogEdit = false
        })
    },
    openDialogEdit(id) {
      this.dialogEdit = true
      this.getOpportunityToEdit(true, id)
    },
    closeDialog() {
      this.opkey++
      this.dialogNewOpportunity = false
    },
    updateOps() {
      this.opkey++
      this.dialogNewOpportunity = false
      this.getOpportunities(true)
    }
  },
  computed: {
    ...mapState('account', ['userData', 'darkTheme'])
  },
  mounted() {
    this.getOpportunities(true)
    this.getStages(true)
  }
}
</script>
